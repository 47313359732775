<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Type d'incident</label>
                <div class="col-lg-10">
                  <multiselect class="test-primary" v-model="data.type" :options="options"></multiselect>
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Titre</label>
                <div class="col-lg-10">
                  <input id="title" v-model="data.titre" name="title" type="text" class="form-control"
                    placeholder="Donnez un titre à l'incident..." />
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Description </label>
                <div class="col-lg-10">
                  <textarea id="projectdesc" v-model="data.description" class="form-control" rows="4"
                    placeholder="Donnez une desciption détaillée de l'incident..."></textarea>
                </div>
              </div>



              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Date</label>
                <div class="col-lg-10">
                  <input type="date" class="form-control" v-model="data.date" placeholder="Date de naissance">
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Lieu</label>
                <div class="col-lg-10">
                  <input id="title" v-model="data.lieu" name="title" type="text" class="form-control"
                    placeholder="Donnez la localisation..." />
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Coordonnées géographique</label>
                <div class="col-lg-10">
                  <div class="row">
                    <div class="col-lg-6">
                      <input id="lat" placeholder="latitude" v-model="Marker.lat" type="text" name="latitude"
                        class="form-control" :disabled="true" />
                    </div>
                    <div class="col-lg-6">
                      <input id="lat" placeholder="longitude" v-model="Marker.lng" type="text" name="longitude"
                        class="form-control" :disabled="true" />
                    </div>
                  </div>
                </div>
                <label for="title" class="col-form-label col-lg-2"></label>
                <div id="map1" class="col-lg-10">
                  <l-map :zoom="zoom" style="height: 500px;" :center="center" @click="handleMapClick">
                    <l-tile-layer :url="url" :maxZoom="maxZoom" />
                    <l-marker @click="handleMarkerClick()" v-if="Object.keys(Marker).length != 0" :visible="true"
                      :lat-lng="Marker">
                      <l-icon :icon-anchor="staticAnchor">
                        <img src="@/assets/images/leaflet/marker-icon.png" />
                      </l-icon>
                    </l-marker>
                    <l-marker v-for="marker in markers" :key="marker.id" :visible="marker.visible"
                      :draggable="marker.draggable" :lat-lng="marker.position" @click="position(marker)">
                      <l-icon v-if="marker.typeAgent == 'moto'" :icon-anchor="staticAnchor">
                        <img src="@/assets/images/leaflet/motorbike.png" />
                      </l-icon>
                      <l-icon v-if="marker.typeAgent == 'car'" :icon-anchor="staticAnchor">
                        <img src="@/assets/images/leaflet/police.png" />
                      </l-icon>
                      <l-icon v-if="marker.typeAgent == 'pied'" :icon-anchor="staticAnchor">
                        <img src="@/assets/images/leaflet/walking.png" />
                      </l-icon>
                    </l-marker>
                  </l-map>
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Personnes impliquées</label>
                <div class="col-lg-10">
                  <div v-for="(person, index) in people" :key="index">
                    <div>
                      <form>
                        <div class="form-group row mb-4">
                          <div class="col-lg-3">
                            <input v-model="person.cni_numero" id="id_number" name="id_number" type="text"
                              class="form-control" placeholder="Numero de la CNI" />
                          </div>
                          <div class="col-lg-3">
                            <input v-model="person.nom" id="nom" name="nom" type="text" class="form-control"
                              placeholder="Nom" />
                          </div>
                          <div class="col-lg-3">
                            <input v-model="person.prenom" id="prenom" name="prenom" type="text" class="form-control"
                              placeholder="Prenom" />
                          </div>
                          <div class="col-lg-3">
                            <input v-model="person.alias" id="alias" name="alias" type="text" class="form-control"
                              placeholder="Alias" />
                          </div>
                        </div>
                        <div class="form-group row mb-4">
                          <div class="col-lg-3">
                            <multiselect class="test-primary" v-model="person.sexe" placeholder="Genre"
                              :options="[{ value: 'masculin', label: 'Masculin' }, { value: 'feminin', label: 'Feminin' }]">
                            </multiselect>
                          </div>
                          <div class="col-lg-3">
                            <input type="date" class="form-control" v-model="person.date_naissance"
                              placeholder="Date de naissance">
                          </div>
                          <div class="col-lg-3">
                            <input id="height" name="height" v-model="person.taille" type="text" class="form-control"
                              placeholder="Taille" />
                          </div>
                          <div class="col-lg-3">
                            <input id="weight" name="weight" v-model="person.poids" type="text" class="form-control"
                              placeholder="Poids" />
                          </div>
                        </div>
                        <div class="form-group row mb-4">
                          <div class="col-lg-4">
                            <input id="teint" name="teint" v-model="person.teint" type="text" class="form-control" placeholder="Teint" />
                          </div>
                          <div class="col-lg-4">
                            <input id="nationalite" name="nationalite" v-model="person.nationalite" type="text" class="form-control" placeholder="Nationalité" />
                          </div>
                          <div class="col-lg-4">
                            <input id="signesParticuliers" name="signes_particuliers" v-model="person.signe_particulier" type="text" class="form-control" placeholder="Signes particuliers" />
                          </div>
                      </div>
                        <div class="row form-group mb-4">
                          <div class="col-lg-12">
                            <input type="checkbox" id="wanted" @click="changeStatut($event, person)">
                            <label for="wanted"> Marquer comme recherché</label>
                          </div>
                        </div>
                        <div class="row form-group mb-4" v-if="person.show">
                       
                          <div class="col-lg-4">
                            <multiselect class="test-primary" placeholder="Dangérosité"
                              v-model="person.niveau_dangerosite" :options="level1"></multiselect>
                          </div>
                          <div class="col-lg-4">
                            <input type="text" v-model="person.conduite_a_tenir" class="form-control"
                              placeholder="Conduite à tenir">
                          </div>
                          <div class="col-lg-4">
                            <input type="text" id="role" name="role" v-model="person.motif_recherche"
                              placeholder="Raison de la recherche" class="form-control">
                          </div>
                        </div>
                        <div class="row mb-4">
                          <div class="col-lg-12">
                            <VueFileAgent :deletable="true" :maxSize="'550MB'" :maxFiles="20" :meta="true"
                              :multiple="true" :uploadUrl="uploadUrl" :accept="'image/*'" v-model="person.photos" />
                          </div>
                        </div>
                      </form>
                      <div class="row mb-4">
                        <div class="col-lg-12 d-grid">
                          <span class="btn btn-danger" @click="removePerson">Supprimer(-)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-lg-12 d-grid">
                      <span class="btn btn-success" @click="addPerson">Ajouter Personne(+)</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Engins impliqués</label>
                <div class="col-lg-10">
                  <div v-for="(auto, index) in autos" :key="index">
                    <div class="row">
                      <form>
                        <div class="form-group row mb-4">
                          <div class="col-lg-3">
                            <input id="immatriculation" v-model="auto.immatriculation" name="immatriculation" type="text"
                              class="form-control" placeholder="Immatriculation" />
                          </div>
                          <div class="col-lg-3">
                            <input id="chassis" v-model="auto.numero_chassis" name="chassis" type="text"
                              class="form-control" placeholder="Numéro châssis" />
                          </div>
                          <div class="col-lg-3">
                            <input id="marque" v-model="auto.marque" name="marque" type="text" class="form-control"
                              placeholder="Marque du véhicule" />
                          </div>
                          <div class="col-lg-3">
                            <input id="modele" v-model="auto.modele" name="modele" type="text" class="form-control"
                              placeholder="Modèle du véhicule" />
                          </div>
                        </div>
                        <div class="form-group row mb-4">
                          <div class="col-lg-4">
                            <multiselect placeholder="type de véhicule" class="test-primary" v-model="auto.type_vehicule"
                              :options="[{ value: 'voiture', label: 'voiture' }, { value: 'moto', label: 'moto' }]">
                            </multiselect>
                          </div>
                          <div class="col-lg-4">
                            <input id="anne" name="anne" v-model="auto.annee" type="text" class="form-control"
                              placeholder="Année du véhicule" />
                          </div>
                          <div class="col-lg-4">
                            <input id="couleur" v-model="auto.couleur" name="couleur" type="text" class="form-control"
                              placeholder="Couleur du véhicule" />
                          </div>
                        </div>
                        <div class="row form-group mb-4">
                          <div class="col-lg-12">
                            <input type="checkbox" id="wantedauto" @click="changeStatutAuto($event, auto)">
                            <label for="wantedauto"> Marquer comme recherché</label>
                          </div>
                        </div>
                        <div class="form-group row mb-4" v-if="auto.show">
                          <div class="col-lg-4">
                            <multiselect class="test-primary" v-model="auto.niveau_dangerosite" placeholder="Dangérosité"
                              :options="level">
                            </multiselect>
                          </div>
                          <div class="col-lg-4">
                            <input type="text" v-model="auto.conduite_a_tenir" class="form-control"
                              placeholder="Conduite à tenir">
                          </div>
                          <div class="col-lg-4">
                            <input id="title" v-model="auto.motif_recherche" name="title" type="text" class="form-control"
                              placeholder="Motif de la recherche" />
                          </div>
                        </div>
                        <div class="row mb-4">
                          <div class="col-lg-12">
                            <VueFileAgent :deletable="true" :maxSize="'550MB'" :maxFiles="20" :meta="true"
                              :multiple="true" :uploadUrl="uploadUrl" :accept="'image/*'" v-model="auto.photos" />
                          </div>
                        </div>
                      </form>
                      <div class="row mb-4">
                        <div class="col-lg-12 d-grid">
                          <span class="btn btn-danger" @click="removeAuto(index)">Supprimer(-)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-lg-12 d-grid">
                      <span class="btn btn-success" @click="addAuto">Ajouter Engin(+)</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Nombre de blessés </label>
                <div class="col-lg-10">
                  <input v-model="data.nbr_blesse" name="title" type="number" class="form-control"/>
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Nombre de décès</label>
                <div class="col-lg-10">
                  <input v-model="data.nbr_deces" name="title" type="number" class="form-control"/>
                </div>
              </div>
              <div class="row justify-content-end">
                <div class="col-md-10">
                  <!-- <button type="reset" title="Ne pas diffuser maintenant" class="btn btn-primary">
                    Annuler l'incident
                  </button> -->
                  <button @click="launchIncident()" type="button" class="btn btn-success" style="float: right"
                    :disabled="spanView">
                    Enregistrer
                    <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                  </button>
                </div>
              </div>


            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
// import Datepicker from "vue3-datepicker";
import Multiselect from "@vueform/multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reqMethods } from '../../../state/helpers';
import Swal from "sweetalert2";
import { VueFileAgent } from '@boindil/vue-file-agent-next';
import '@boindil/vue-file-agent-next/dist/vue-file-agent-next.css';
import { LMap, LMarker, LTileLayer, LIcon } from "@vue-leaflet/vue-leaflet";
// import { latLng } from "leaflet";
import axios from 'axios';

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    VueFileAgent,
    // Datepicker,
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  data() {
    return {
      isUpdatingMarker: false,
      zoom: 12,
      maxZoom: 25,
      center: {
        lat: null,
        lng: null
      },
      url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      title: "Ajouter un nouveau incident",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Nouvel incident",
          active: true,
        },
      ],
      // "{\"nom\":\"Alice\",\"age\":30}/{\"nom\":\"Bob\",\"age\":25}/{\"nom\":\"Charlie\",\"age\":35}"
      markers: {},
      Marker: {},
      people: [],
      autos: [],
      Incidentfiles: [],
      spanView: false,
      data: {
        titre: null,
        type: null,
        description: null,
        location: null,
        lieu: null,
        date: null,
        photos: [],
        videos: [],
        audios: [],
        files: [],
        agentcenter: 1,
        agent: 0,
        nbr_deces: 0,
        nbr_blesse: 0
      },
      level: [{ value: 'normal', label: 'Normal' },
      { value: 'dangereux', label: 'Dangereux' },],
      level1: [
        { value: 'normal', label: 'Normal' },
        { value: 'dangereux', label: 'Dangereux' },
      ],
      conduite: ["Normal", "Prudence"],
      options: [

      ],
      personnePic: [],
      carPic: [],
      personnes: [],
      cars: [],
      zones: ["Zone 1", "Zone 2", "Zone 3"],
      zone: null,
      thematique: null,
      currentIncidentID: null,
      reqError: null,
      response: null,
    };
  },
  methods: {
    ...reqMethods,
    addPerson() {
      this.people.push({
        cni_numero: '',
        nom: '',
        prenom: '',
        alias: '',
        taille: '',
        poids: '',
        teint: '',
        date_naissance: '',
        sexe: '',
        signe_particulier: '',
        status: '',
        show: false,
        motif_recherche: '',
        niveau_dangerosite: '',
        conduite_a_tenir: '',
        photos: []
      });
    },
    addAuto() {
      this.autos.push({
        immatriculation: '',
        numero_chassis: '',
        marque: '',
        modele: '',
        annee: '',
        couleur: '',
        type_vehicule: '',
        show: false,
        statut: '',
        motif_recherche: '',
        niveau_dangerosite: '',
        conduite_a_tenir: '',
        photos: []
      });
    },

    removePerson(index) {
      this.people.splice(index, 1);
    },

    removeAuto(index) {
      this.autos.splice(index, 1);
    },
    compilePersonnes() {
      this.people.forEach(element => {
        this.personnePic.push(element.photos)
      });

      this.personnes = this.people.map(function (person) {
        const personneSansPhotos = { ...person };
        delete personneSansPhotos.photos;
        return JSON.stringify(personneSansPhotos);
      }).join('/');
    },

    compileVehicules() {
      this.autos.forEach(element => {
        this.carPic.push(element.photos)
      });

      this.cars = this.autos.map(function (auto) {
        const autoSansPhotos = { ...auto };
        delete autoSansPhotos.photos;
        return JSON.stringify(autoSansPhotos);
      }).join('/');
    },

    changeStatut(event, person) {
      person.show = event.target.checked;
      person.status = person.show ? 'rechercher' : 'non_rechercher';
    },
    changeStatutAuto(event, auto) {
      auto.show = event.target.checked;
      auto.statut = auto.show ? 'rechercher' : 'non_rechercher';
    },

    async launchIncident() {
      this.spanView = true;
      this.createIncidentFiles();
      this.compilePersonnes();
      this.compileVehicules();
      const formData = new FormData();
      formData.append('titre', this.data.titre);
      formData.append('type', this.data.type);
      formData.append('description', this.data.description);
      formData.append('location', this.data.location);
      formData.append('lieu', this.data.lieu);
      formData.append('date', this.data.date);
      formData.append('agentcenter', this.data.agentcenter);
      formData.append('agent', this.data.agent);
      formData.append('personne', this.personnes);
      formData.append('vehicule', this.cars);
      formData.append('death', this.data.nbr_deces);
      formData.append('hurt', this.data.nbr_blesse);
      if (this.personnePic.length > 0) {
        const peoplePics = new FormData();
        for (const picArray of this.personnePic) {
          const personImgs = new FormData();
          for (const image of picArray) {
            personImgs.append('photos', image);
          }
          peoplePics.append('personImages', formData)
        }
        formData.append('personnesPics', peoplePics);
      }

      if (this.carPic.length > 0) {
        const carsPics = new FormData();
        for (const picArray of this.carPic) {
          const carImgs = new FormData();
          for (const image of picArray) {
            carImgs.append('photos', image);
          }
          carsPics.append('carImages', formData)
        }
        formData.append('vehiculesPics', carsPics);
      }

      const fileCategories = ['photos', 'videos', 'audios', 'files'];
      for (const category of fileCategories) {
        for (const file of this.data[category]) {
          formData.append(category, file);
        }
      }

      const that = this;
      try {
        const response = await axios.post('https://api.alcit.sims-technologie.com/api/v1/investigation/incident/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token'),
          },
        });
        that.spanView = false;
        console.log(response.data)
        Swal.fire(response.data.message, "L'incident à été Ajouté!", "success");
        this.$router.push({ path: `/list-incident` });
      } catch (error) {
        that.spanView = false;
        that.reqError = error;
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors de l'ajout de l'incident",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },

    createIncidentFiles() {
      this.Incidentfiles.forEach(element => {
        if (element.file.type.includes('image/')) {
          if (element.modelType === 'person') {
            const personIndex = element.modelIndex;
            this.people[personIndex].photos.push(element.file);
          } else if (element.modelType === 'auto') {
            const autoIndex = element.modelIndex;
            this.autos[autoIndex].photos.push(element.file);
          }
        } else if (element.file.type == 'application/pdf') {
          this.data.files.push(element.file);
        } else if (element.file.type.includes('video/')) {
          this.data.videos.push(element.file);
        } else if (element.file.type.includes('audio/')) {
          this.data.audios.push(element.file);
        }
      });
    },
    getIncidentType() {
      const that = this;
      this.getRequest('/investigation/type-incident/')
        .then(
          function (response) {
            response.data.forEach(element => {
              that.options.push({ value: element.id, label: element.name });
            });
          },
          function (error) {
            that.reqError = error;
          }
        )
    },
    handleMapClick(event) {
      if (event, event.latlng) {
        const { lat, lng } = event.latlng;
        this.data.location = `POINT(${lat} ${lng})`;
        if (Object.keys(this.Marker).length === 0) {
          this.Marker = { lat: lat, lng: lng };
        } else {
          this.Marker = {};
          this.Marker = { lat: lat, lng: lng };
        }
      }
    },

    handleMarkerClick() {
      this.Marker = {};
      this.data.location = null;
    }
  },
  mounted() {
    this.getIncidentType();
    const that = this;
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        that.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
      }, function (error) {
        alert("Erreur de géolocalisation : " + error.message);
      });
    } else {
      alert("La géolocalisation n'est pas prise en charge par ce navigateur.");
    }
  },
};
</script>